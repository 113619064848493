import React, { useState } from 'react';
import TopBar from './../../../layout/TopBar';
import FolderGrid from './../../widgets/FolderGrid';
import PlusCircle from '../../../macadamia-icons/plus-circle';
import CreateFolderModal from './../../CreateFolderModal';
import FilterTabs from './../../widgets/FilterTabs';
import RessourcesSidebar from './RessourcesSidebar';

const MyFolders = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dossiersUpdated, setDossiersUpdated] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>('Tous');

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFolderCreated = (newFolder: any) => {
    setDossiersUpdated(true);
    setIsModalOpen(false);
  };

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
  };

  return (
    <div className="min-h-screen bg-mcdm-primary-100 dark:bg-mcdm-third-500">
      <TopBar />

      <div className="pt-24 pb-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto flex gap-6 items-start">
          {/* Main content */}
          <div className="w-4/5">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-4">
              <div className="flex items-center">
                <h1 className="text-xl font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100">
                  Mes Dossiers
                </h1>
                <button
                  onClick={openModal}
                  className="ml-4 text-mcdm-third-500 dark:text-mcdm-primary-100 hover:text-mcdm-secondary-400 dark:hover:text-mcdm-secondary-300 transition-colors"
                >
                  <PlusCircle width="24px" height="24px" fill="currentColor" />
                </button>
              </div>

              <FilterTabs
                selectedFilter={selectedFilter}
                onFilterChange={handleFilterChange}
              />
            </div>

            <FolderGrid
              dossiersUpdated={dossiersUpdated}
              setDossiersUpdated={setDossiersUpdated}
              selectedFilter={selectedFilter}
            />
          </div>

          {/* Right Sidebar */}
          <RessourcesSidebar />
        </div>
      </div>

      <CreateFolderModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onFolderCreated={handleFolderCreated}
      />
    </div>
  );
};

export default MyFolders;
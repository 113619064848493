import React from 'react';

interface ArticleData {
    id: string;
    title: string;
    text: string;
}

interface ArticleDisplayProps {
    article: ArticleData;
}

const ARTICLE_PLACEHOLDER = {
    id: 'LEGIARTI000006419293',
    title: 'Article 34',
    text: `Les actes de naissance sont établis immédiatement après la naissance.
  
Lorsqu'un accouchement a eu lieu dans un établissement de santé, le directeur ne peut refuser de déclarer la naissance conformément aux dispositions de l'article 56.

La naissance de l'enfant sera déclarée par le père, ou à défaut du père, par les docteurs en médecine ou en chirurgie, sages-femmes, officiers de santé ou autres personnes qui auront assisté à l'accouchement.`
};

export const ArticleDisplay: React.FC<ArticleDisplayProps> = ({ article = ARTICLE_PLACEHOLDER }) => {
    return (
        <div className="mb-4">
            <div className="text-sm text-gray-500 mb-1">
                Article {article.id}
            </div>
            <h3 className="text-lg font-medium text-gray-800 mb-4">
                {article.title}
            </h3>
            <div className="text-sm bg-white rounded p-4 whitespace-pre-wrap break-words">
                {article.text}
            </div>
        </div>
    );
};
import React from 'react';

interface SectionData {
    id: string;
    title: string;
    description?: string;
}

interface SectionDisplayProps {
    section: SectionData;
}

const SECTION_PLACEHOLDER = {
    id: 'LEGISCTA000006089696',
    title: 'Chapitre II : Des actes de naissance',
    description: 'Cette section détaille les règles applicables aux actes de naissance en droit français.'
};

export const SectionDisplay: React.FC<SectionDisplayProps> = ({ section = SECTION_PLACEHOLDER }) => {
    return (
        <div className="mb-6 pb-4 border-b border-gray-200">
            <div className="text-sm text-gray-500 mb-1">
                Section {section.id}
            </div>
            <h3 className="text-lg font-medium text-gray-800 mb-2">
                {section.title}
            </h3>
            {section.description && (
                <p className="text-sm text-gray-600">
                    {section.description}
                </p>
            )}
        </div>
    );
};
import React from 'react';

interface LegifranceSection {
    id: string;
    title: string;
    dateVersion: string;
    legalStatus: string;
    extracts: {
        id: string;
        title: string;
        values: string[];
        type: string;
        legalStatus: string;
        dateVersion: string;
        dateDebut: string;
        dateFin: string;
    }[];
}

interface LegifranceResult {
    titles: {
        id: string;
        title: string;
    }[];
    nature: string;
    date: string;
    etat: string;
    sections?: LegifranceSection[];
}

interface ResultCodesCardProps {
    result: LegifranceResult;
    onSelect: (reference: {
        id: string;
        type: 'CODE' | 'SECTION' | 'ARTICLE';
        title: string;
        codeId?: string;
        sectionId?: string;
    }) => void;
}

const LegifranceResultCodesCard: React.FC<ResultCodesCardProps> = ({ result, onSelect }) => {
    const formatTextWithMarks = (text: string) => {
        if (!text) return '';
        const parts = text.split(/(<mark>.*?<\/mark>)/g);
        return parts.map((part, index) => {
            if (part.startsWith('<mark>') && part.endsWith('</mark>')) {
                const markedText = part.replace(/<\/?mark>/g, '');
                return (
                    <span
                        key={index}
                        className="bg-mcdm-secondary-100 dark:bg-mcdm-secondary-700 text-mcdm-third-500 dark:text-mcdm-primary-100 py-0.5 px-1 rounded font-medium"
                    >
                        {markedText}
                    </span>
                );
            }
            return part;
        });
    };

    return (
        <div className="rounded-lg border border-mcdm-primary-200 dark:border-mcdm-primary-200 overflow-hidden">
            <div
                onClick={() => result.titles?.[0]?.id && onSelect({
                    id: result.titles[0].id,
                    type: 'CODE',
                    title: result.titles[0].title
                })}
                className="p-3 hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-300 cursor-pointer group relative font-platypi"
                title="Sélectionner le code"
            >
                <div className="text-sm font-medium text-left text-mcdm-secondary-500 dark:text-mcdm-primary-100">
                    {formatTextWithMarks(result.titles?.[0]?.title || 'Titre inconnu')}
                </div>
            </div>

            {result.sections?.map((section, sectionIndex) => (
                <div key={sectionIndex} className="border-t border-mcdm-primary-200">
                    <div
                        onClick={() => onSelect({
                            id: section.id,
                            type: 'SECTION',
                            title: section.title,
                            codeId: result.titles[0].id
                        })}
                        className="p-2 pl-6 hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-300 cursor-pointer relative"
                        title="Sélectionner la section"
                    >
                        <div className="text-sm text-mcdm-third-500 dark:text-mcdm-primary-100">
                            {section.title}
                        </div>
                    </div>

                    {section.extracts?.map((article, articleIndex) => (
                        <div
                            key={articleIndex}
                            onClick={() => onSelect({
                                id: article.id,
                                type: 'ARTICLE',
                                title: `Article ${article.title}`,
                                codeId: result.titles[0].id,
                                sectionId: section.id
                            })}
                            className="p-2 pl-9 hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-300 cursor-pointer border-t border-mcdm-primary-200 relative"
                            title="Sélectionner l'article"
                        >
                            <div className="text-sm text-mcdm-third-400 dark:text-mcdm-primary-200">
                                Article {article.title}
                            </div>
                            <div className="text-xs text-mcdm-third-300 dark:text-mcdm-primary-300 mt-1">
                                {article.values?.[0]}
                            </div>
                        </div>
                    ))}
                </div>
            ))}

        </div>
    );
};

export default LegifranceResultCodesCard;
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../../context/AuthContext';
import LegifranceResultCodesCard from './LegifranceResultCodesCard';

interface LegifranceSearchProps {
    onSelect: (reference: { 
        id: string;
        type: 'CODE' | 'SECTION' | 'ARTICLE';
        title: string;
        codeId?: string;
        sectionId?: string;
    }) => void;
}

interface LegifranceResult {
    titles: {
        id: string;
        title: string;
    }[];
    nature: string;
    date: string;
    etat: string;
    nor: string;
    sections?: any[];
    text?: string;
    datePublication?: string;
    dateSignature?: string;
}

const LegifranceSearch: React.FC<LegifranceSearchProps> = ({ onSelect }) => {
    const { token } = useAuth();
    const [searchResults, setSearchResults] = useState<LegifranceResult[]>([]);
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest('.legifrance-sidebar') && !target.closest('.legifrance-button')) {
                setIsVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleReferenceSelect = (reference: { id: string; type: 'CODE' | 'SECTION' | 'ARTICLE'; title: string }) => {
        if (!reference.id) return;
        onSelect(reference);
        setIsVisible(false);
    };

    const searchLegifrance = async (userQuery?: string) => {
        const finalQuery = userQuery || window.getSelection()?.toString().trim() || searchQuery;
        if (!finalQuery) return;

        setIsLoading(true);
        setIsVisible(true);
        setSearchQuery(finalQuery);

        try {
            const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/legifrance/search`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ query: finalQuery }),
            });

            const data = await response.json();

            if (!response.ok) {
                console.error('Erreur API:', data);
                throw new Error(data.error?.message || 'Erreur lors de la recherche Légifrance');
            }

            if (data.results) {
                const filteredResults = data.results
                    .filter((result: LegifranceResult) => 
                        result.titles && 
                        result.titles.length > 0 &&
                        result.nature === "CODE"
                    );
                setSearchResults(filteredResults);
            } else {
                console.error('Réponse API invalide:', data);
                throw new Error('Format de réponse invalide');
            }
        } catch (error) {
            console.error('Erreur détaillée lors de la requête API Légifrance:', error);
            setSearchResults([]);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="flex gap-2">
                <button
                    onClick={() => searchLegifrance()}
                    className="legifrance-button px-2 py-1 hover:bg-mcdm-primary-200 rounded text-mcdm-third-500 text-sm"
                >
                    Légifrance
                </button>
            </div>

            {isVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-20 z-30 transition-opacity duration-300"
                    onClick={() => setIsVisible(false)}>
                    <div className="legifrance-sidebar fixed inset-y-0 right-0 w-[320px] bg-mcdm-primary-100 dark:bg-mcdm-third-400 shadow-lg shadow-inner z-30 overflow-y-auto transform transition-transform duration-300"
                        onClick={(e) => e.stopPropagation()}>
                        <div className="p-4">
                            <div className="space-y-4">
                                {isLoading ? (
                                    <div className="space-y-4">
                                        {Array(5).fill(0).map((_, index) => (
                                            <div key={index} className="mb-4 animate-pulse">
                                                <div className="h-4 bg-mcdm-primary-200 dark:bg-mcdm-primary-100 rounded w-full mb-2"></div>
                                                <div className="space-y-2">
                                                    {Array(8).fill(0).map((_, i) => (
                                                        <div key={i} className="h-3 bg-mcdm-primary-200 dark:bg-mcdm-primary-100 rounded w-full"></div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        <div className="sticky pt-20 top-0 bg-mcdm-primary-100 dark:bg-mcdm-third-400 p-4 border-b border-mcdm-primary-200 dark:border-mcdm-primary-300 z-20">
                                            <p className="text-sm text-mcdm-third-500 dark:text-mcdm-primary-100 font-platypi">
                                                {searchResults.length} résultat{searchResults.length !== 1 ? 's' : ''} trouvé{searchResults.length !== 1 ? 's' : ''}
                                            </p>
                                            {searchQuery && (
                                                <p className="text-xs text-mcdm-third-400 dark:text-mcdm-primary-200 mt-1">
                                                    Recherche : "{searchQuery}"
                                                </p>
                                            )}
                                        </div>
                                        <div className="space-y-4">
                                            {searchResults
                                                .filter(result => result.nature === "CODE")
                                                .map((result, index) => (
                                                    <LegifranceResultCodesCard
                                                        key={index}
                                                        result={result}
                                                        onSelect={handleReferenceSelect}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default LegifranceSearch;
import React from 'react';

interface CodeData {
    id: string;
    title: string;
    description?: string;
}

interface CodeDisplayProps {
    code: CodeData;
}

const CODE_PLACEHOLDER = {
    id: 'LEGITEXT000006070721',
    title: 'Code Civil',
    description: 'Le Code civil est un ensemble de lois qui définit les règles relatives aux rapports entre les personnes.'
};

export const CodeDisplay: React.FC<CodeDisplayProps> = ({ code = CODE_PLACEHOLDER }) => {
    return (
        <div className="mb-6 pb-4 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
                {code.title}
            </h2>
            <div className="text-sm text-gray-600 mb-2">
                Code {code.id}
            </div>
            {code.description && (
                <p className="text-sm text-gray-600">
                    {code.description}
                </p>
            )}
        </div>
    );
};
import React from 'react';

const RessourcesSidebar = () => {
  return (
    <div className="w-1/5">
      <div className="bg-white dark:bg-mcdm-third-400 border border-white dark:border-mcdm-third-400 rounded-lg p-4">
        <h2 className="text-mcdm-third-500 dark:text-mcdm-primary-100 font-platypi text-lg mb-4">
          Ressources recommandées :
        </h2>
        <p className="text-mcdm-third-500 dark:text-mcdm-primary-100 text-sm">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
      </div>
    </div>
  );
};

export default RessourcesSidebar;
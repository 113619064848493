import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../context/AuthContext';

interface JurisprudenceSearchProps {
    onSelect: (reference: string) => void;
}

interface SearchResult {
    summary?: string;
    number?: string;
    decision_date?: string;
    jurisdiction?: string;
    chamber?: string;
    solution?: string;
    id?: string;
    location?: string;
}

const JurisprudenceSearch: React.FC<JurisprudenceSearchProps> = ({ onSelect }) => {
    const { token } = useAuth();
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest('.jurisprudence-sidebar') && !target.closest('.jurisprudence-button')) {
                setIsVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const searchJurisprudence = async () => {
        const selection = window.getSelection();
        const content = selection ? selection.toString().trim() : "";
        if (!content) return;

        setIsLoading(true);
        setIsVisible(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/jurisprudences/search`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ query: content }),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la recherche de jurisprudence');
            }

            const data = await response.json();
            setSearchResults(data);
        } catch (error) {
            console.error('Erreur lors de la requête API interne:', error);
            alert('Erreur lors de la recherche de jurisprudence. Veuillez réessayer.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleResultSelect = (result: SearchResult) => {
        onSelect(result.id || '');
        setIsVisible(false);
    };

    const generateTitle = (result: SearchResult) => {
        if (result.jurisdiction === 'Cour d\'appel') {
            const locationPart = result.location
                ? `de ${result.location.replace(/^Cour d'appel de /, '')}`
                : '';
            return `${result.decision_date || 'Date inconnue'} - Cour d'appel ${locationPart} ${result.number ? `- RG n° ${result.number}` : ''}`.trim();
        }
        return `${result.decision_date || 'Date inconnue'} - ${result.jurisdiction || 'Juridiction inconnue'} ${result.number ? `n° ${result.number}` : ''}`.trim();
    };

    return (
        <>
            <button
                onClick={searchJurisprudence}
                className="jurisprudence-button px-2 py-1 hover:bg-mcdm-primary-200 rounded text-mcdm-third-500 text-sm"
            >
                Jurisprudence
            </button>

            {isVisible && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-20 z-30 transition-opacity duration-300"
                    onClick={() => setIsVisible(false)}
                >
                    <div
                        className="jurisprudence-sidebar fixed inset-y-0 right-0 w-[320px] bg-mcdm-primary-100 dark:bg-mcdm-third-400 shadow-lg shadow-inner z-30 overflow-y-auto transform transition-transform duration-300"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="p-4">
                            {isLoading ? (
                                Array(5).fill(0).map((_, index) => (
                                    <div key={index} className="mb-4 animate-pulse">
                                        <div className="h-4 bg-mcdm-primary-200 dark:bg-mcdm-primary-100 rounded w-full mb-2"></div>
                                        <div className="space-y-2">
                                            {Array(8).fill(0).map((_, i) => (
                                                <div key={i} className="h-3 bg-mcdm-primary-200 dark:bg-mcdm-primary-100 rounded w-full"></div>
                                            ))}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="space-y-4">
                                    <p className="mt-16 font-platypi text-mcdm-third-500 text-lg dark:text-mcdm-primary-100 text-left">Sélectionnez une jurisprudence :</p>
                                    {searchResults.map((result, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleResultSelect(result)}
                                            className="p-3 hover:bg-mcdm-primary-200 dark:hover:bg-mcdm-third-300 cursor-pointer rounded-lg border border-mcdm-primary-200 dark:border-mcdm-primary-200"
                                        >
                                            {result.jurisdiction !== 'Cour de cassation' && (
                                                <div className="font-medium font-platypi text-mcdm-third-500 dark:text-mcdm-primary-100">{generateTitle(result)}</div>
                                            )}
                                            {result.jurisdiction === 'Cour de cassation' ? (
                                                <>
                                                    <div className="mb-2 text-sm"><strong>Résumé : </strong>{result.summary || 'Non disponible'}</div>
                                                    <div className="grid grid-cols-2 gap-2">
                                                        <span className="text-left bg-mcdm-primary-100 dark:bg-mcdm-third-500 text-mcdm-third-400 dark:text-mcdm-primary-100 px-2 py-1 rounded text-sm shadow">Numéro : {result.number || 'N/A'}</span>
                                                        <span className="text-left bg-mcdm-primary-100 dark:bg-mcdm-third-500 dark:text-mcdm-primary-100 px-2 py-1 rounded text-sm shadow">Date : {result.decision_date || 'N/A'}</span>
                                                        <span className="text-left bg-mcdm-primary-100 dark:bg-mcdm-third-500 dark:text-mcdm-primary-100 px-2 py-1 rounded text-sm shadow">Juridiction : {result.jurisdiction || 'N/A'}</span>
                                                        <span className="text-left bg-mcdm-primary-100 dark:bg-mcdm-third-500 dark:text-mcdm-primary-100 px-2 py-1 rounded text-sm shadow">Chambre : {result.chamber || 'N/A'}</span>
                                                        <span className="text-left bg-mcdm-primary-100 dark:bg-mcdm-third-500 dark:text-mcdm-primary-100 px-2 py-1 rounded text-sm shadow">Solution : {result.solution || 'N/A'}</span>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="grid grid-cols-2 gap-2 mt-2">
                                                    <span className="text-left bg-mcdm-primary-100 dark:bg-mcdm-third-500 dark:text-mcdm-primary-100 px-2 py-1 rounded text-sm shadow">Numéro : {result.number || 'N/A'}</span>
                                                    <span className="text-left bg-mcdm-primary-100 dark:bg-mcdm-third-500 dark:text-mcdm-primary-100 px-2 py-1 rounded text-sm shadow">Date : {result.decision_date || 'N/A'}</span>
                                                    <span className="text-left bg-mcdm-primary-100 dark:bg-mcdm-third-500 dark:text-mcdm-primary-100 px-2 py-1 rounded text-sm shadow">Juridiction : {result.jurisdiction || 'N/A'}</span>
                                                    <span className="text-left bg-mcdm-primary-100 dark:bg-mcdm-third-500 dark:text-mcdm-primary-100 px-2 py-1 rounded text-sm shadow">Chambre : {result.chamber || 'N/A'}</span>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </>
    );
};

export default JurisprudenceSearch;